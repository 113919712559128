import { initClient } from '@sunrise/client'
import { defineAsyncComponent } from 'vue'
import { initHotReload } from './hotReload'

const dynamicImports = {
    CsTourDetailStage: defineAsyncComponent(() => import('./components/CsTourDetailStage/CsTourDetailStage.vue')),
    CsTourDescription: defineAsyncComponent(() => import('./components/CsTourDescription/CsTourDescription.vue')),
    CsTourDetailFooter: defineAsyncComponent(() => import('./components/CsTourDetailFooter/CsTourDetailFooter.vue')),
    CsPoiDescription: defineAsyncComponent(() => import('./components/CsPoiDescription/CsPoiDescription.vue')),
    HydratedResultList: defineAsyncComponent(
        () => import('./cmsElements/M/M_OA_Resultlist/HydratedResultList/HydratedResultList.vue'),
    ),
    HydratedDiscoverResultList: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_Leisure_Discover_Resultlist/HydratedDiscoverResultList/HydratedDiscoverResultList.vue'
            ),
    ),
    HydratedLandingPageStage: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_Leisure_Landing_Page_Stage/HydratedLandingPageStage/HydratedLandingPageStage.vue'
            ),
    ),
    HydratedPoiResultList: defineAsyncComponent(
        () => import('./cmsElements/M/M_Leisure_Poi_Resultlist/HydratedPoiResultList/HydratedPoiResultList.vue'),
    ),
}

initClient({
    adsEnabled: true,
    dynamicImports,
})

initHotReload()
